import styled from '@emotion/styled'

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem 2rem;
`

export default Container
