import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { mq } from './styles/theme'
import logo from '../images/logo.png'

const Container = styled.header`
  text-align: center;
  margin: 3rem 0;

  ${mq[2]} {
    color: green;
    margin-top: 2rem;
  }
`

const TextContainer = styled.div`
  text-align: center;
  margin: 0;
  padding: 1.5rem;
  line-height: 0;
`

const Title = styled.h1`
  font-size: 1.2rem;
  letter-spacing: 3px;
  margin: 0;
`

const Practitioner = styled.h2`
  font-size: 0.9rem;
  margin: 0;
`

const Ruler = styled.hr`
  display: block;
  margin: 0.8rem auto;
  padding: 0;
  width: 2.5rem;
  heigth: 2px;
  border: none;
  background-color: ${props => props.theme.colors.primary};
`

const Header = () => (
  <Container>
    <Link
      to='/'
      css={theme => ({
        color: theme.colors.primary,
        textDecoration: `none`
      })}
    >
      <img src={logo} alt='Logo' css={{ height: '180px' }} />
      <TextContainer>
        <Title>HOMÖOPATHIE</Title>
        <Ruler />
        <Practitioner>Clelia Sasselli</Practitioner>
      </TextContainer>
    </Link>
  </Container>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
