import React from "react"
import { Global, css } from "@emotion/core"
import { withTheme } from "emotion-theming"

const Main = ({ theme }) => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
        font-family: Roboto, sans-serif;
      }
      body {
        font-weight: 300;
        color: ${theme.colors.body};
      }
      h1,
      h2,
      h3 {
        font-weight: 100;
      }
      h3 {
        margin-top: 2krem;
      }
      a,
      a.active {
        color: ${theme.colors.primary};
      }

      table tr td:first-of-type {
        vertical-align: top;
        color: ${theme.colors.primary}
      }
    `}
  />
)

export default withTheme(Main)
