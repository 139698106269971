import React from "react"
import { Link } from "gatsby"
import Container from "../components/Container"
import styled from "@emotion/styled"

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.body};
  margin-right: 2rem;
`

const NavItem = ({ to, children }) => (
  <NavLink to={to} activeClassName="active">
    {children}
  </NavLink>
)

const Nav = styled.nav``

const Navigation = () => (
  <Container>
    <Nav>
      <NavItem to="/aktuell_corona">AKTUELL-CORONA</NavItem>
      <NavItem to="/homoeopathie">HOMÖOPATHIE</NavItem>
      <NavItem to="/angebot">ANGEBOT</NavItem>
      <NavItem to="/behandlung">BEHANDLUNG</NavItem>
      <NavItem to="/ueber_mich">ÜBER MICH</NavItem>
      <NavItem to="/praxis">PRAXIS</NavItem>
    </Nav>
  </Container>
)

export default Navigation
