import React from "react"
import Container from "../components/Container"
import styled from "@emotion/styled"

const OuterContainer = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.gray};
  width: 100%;
  font-size: 0.8rem;
`

const Footer = () => (
  <OuterContainer>
    <Container css={{ paddingTop: "2rem" }}>
      <h3>HOMÖOPATHIE &mdash; Clelia Sasselli</h3>
      <p>
        Clelia Sasselli
        <br />
        Dipl. Homöopathin SkHZ
        <br />
        <br />
        Praxis am Park
        <br />
        Stampfenbachstr. 123
        <br />
        8006 Zürich
      </p>
      <p>076 492 50 20</p>
      <p>
        praxis@homoeopathie-zueri.ch
        <br />
        www.homöopathie-züri.ch
      </p>
      <p>
        Webdesign Delia Schneider Rizza -{" "}
        <a
          href="https://www.seinschein.ch/"
          target="_blank"
          rel="noreferrer noopener"
        >
          www.seinschein.ch
        </a>
      </p>
    </Container>
  </OuterContainer>
)

export default Footer
