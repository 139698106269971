import React from 'react'
import { Global, css } from '@emotion/core'
import robotoLight from '../../fonts/Roboto-Light.ttf'
import robotoThin from '../../fonts/Roboto-Thin.ttf'

const FontFace = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        src: local('Roboto Light'), url(${robotoLight}) format('truetype');
      }
      @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        src: local('Roboto Thin'), url(${robotoThin}) format('truetype');
      }
    `}
  />
)

export default FontFace
