module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"homoeopathie-clelia-sasselli","short_name":"clelia-sasselli","start_url":"/","background_color":"#FFFFFF","theme_color":"#008ea8","display":"minimal-ui","icon":"src/images/spiralIcon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
