export default {
  colors: {
    body: '#7F7F7E',
    primary: '#008ea8',
    secondary: '#FAF8E9',
    gray: '#939597'
  },
  maxWidth: 1200
}

const breakpoints = [580, 839, 1024]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export { mq }
