// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuell-corona-js": () => import("./../src/pages/aktuell_corona.js" /* webpackChunkName: "component---src-pages-aktuell-corona-js" */),
  "component---src-pages-angebot-js": () => import("./../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-behandlung-js": () => import("./../src/pages/behandlung.js" /* webpackChunkName: "component---src-pages-behandlung-js" */),
  "component---src-pages-homoeopathie-js": () => import("./../src/pages/homoeopathie.js" /* webpackChunkName: "component---src-pages-homoeopathie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-praxis-js": () => import("./../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../src/pages/ueber_mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

