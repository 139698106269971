import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "emotion-theming"
import ResetStyles from "./styles/Reset"
import FontFaceStyles from "./styles/FontFace"
import MainStyles from "./styles/Main"
import Header from "./Header"
import Navigation from "./Navigation"
import Footer from "./Footer"
import Transition from "./Transition"
import theme from "./styles/theme"

const Layout = ({ children, location }) => (
  <ThemeProvider theme={theme}>
    <ResetStyles />
    <FontFaceStyles />
    <MainStyles />
      <Header />
      <Navigation />
      <Transition location={location}>
        <main>{children}</main>
      </Transition>
      <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
